.home-container {
  @apply w-full flex align-middle flex-col m-auto;
  .header-section {
    @apply relative z-[300] h-[56px] w-full flex justify-between items-center px-2 box-border ml-auto mr-auto min-[993px]:min-w-[200px] max-w-[1440px] md:px-5;
  }
  .banner-section {
    @apply w-full items-start flex justify-start min-[993px]:min-w-[200px] max-w-[1440px] box-border ml-auto mr-auto px-2 md:px-5;
    h2 {
      @apply text-left text-[24px] font-bold leading-8 text-white;
    }
    h3 {
      @apply text-left text-white;
    }
  }
  .latest-all {
    @apply w-full flex min-[1100px]:flex-row mt-4 gap-4 box-border flex-col flex-wrap min-[1100px]:min-w-[200px] max-w-[1440px] ml-auto mr-auto px-2 md:px-5;
    > .home-latest {
      @apply min-[993px]:w-[50%] flex-1 w-full;
    }
    &-mobile {
      .producer {
        @apply !min-w-[200px] !max-w-[300px];
      }
    }
  }
  .search-section {
    @apply flex items-center justify-center w-full;
  }

  .banner-title {
    transition: color 0.8s ease-in-out;
  }
}

.banner-section-container {
  transition: background-color 0.8s ease-in-out;
  .home-animate-text {
    animation: slide-in 0.5s ease forwards;
    @apply min-w-[auto] md:min-w-[224px];
  }
}

.banner-bg {
  background-image: url('/image/bg-home-banner.png');
  @apply absolute -bottom-[14px]  aspect-[393/85]  min-[400px]:-bottom-[16px] min-[400px]:aspect-[4096/886]  max-h-[400px] w-full bg-cover bg-no-repeat mix-blend-multiply md:!-bottom-2 md:aspect-[1024/232] md:bg-center min-[1025px]:!-bottom-[53px] min-[1025px]:aspect-[1024/311] min-[1500px]:bg-top;
}


@keyframes slide-in {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}